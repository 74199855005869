import authRoutes, { authGuard, getDefaultRoute } from './auth';
import adminRoutes from './admin';
import customerRoutes from './customer';

import Vue from 'vue';
import VueRouter from 'vue-router';

/** @typedef {import('vue-router').RouteLocationRaw} RouteLocationRaw */

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: () => getDefaultRoute(),
    },
    {
        name: '404',
        path: '/404',
        component: () => import('../views/404.vue'),
    },
    {
        name: 'InsufficientPermissions',
        path: '/insufficient-permissions',
        component: () => import('../views/InsufficientPermissions.vue'),
    },

    ...authRoutes,

    // Adds the appropriate path prefix to route records
    ...adminRoutes.map(record => ({ ...record, path: '/admin' + record.path })),
    ...customerRoutes.map(record => ({ ...record, path: '/customer' + record.path })),

    {
        path: '*',
        redirect: '404',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes,
});

router.beforeEach(authGuard);

/**
 * Gets the absolute URL for a route relative to ``window.location``
 * @param {RouteLocationRaw} route A route location
 * @returns {string} An absolute URL
 */
export const resolveAbsoluteURL = (route) => {
    const { href } = router.resolve(route);
    return new URL(href, window.location.origin).href;
};

// Track page change events with umami
router.afterEach((to, from, failure) => {
    if (!window.umami) return;
    if (failure) return;

    if (to.path !== from.path) {
        const isInitial = from.matched.length < 1;
        const referrer = isInitial ? document.referrer : resolveAbsoluteURL(from.path);

        window.umami.track((props) => ({ ...props, url: to.path, referrer }));
    }
});

export default router;
